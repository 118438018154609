<template>
	<div
		class="chat-body p-5"
		style="background-color:rgba(0,0,0,.021); margin: 0 auto; font-size: 14px; overflow-x:hidden;"
	>
		<div class="card bg-deepink chat-box" id="chat3">
			<div class="row">
				<div class="col-md-4 col-lg-4 col-xl-3 chatSideBar py-3" :class="isShow ? 'expand' : ''">
				<div class="header-sec">
					<div class="userHead w-full">
						<b-form-input class="rounded-pill" v-model="search_room" placeholder="Search..."></b-form-input>
					</div>
					<div id="hamberger" class="hamberger-menu ml-2" @click="isShow = !isShow" :class="unreadnotification ? 'unreadnotification' : ''">
						<span></span>
						<span :class="unreadnotification ? 'bubble' : ''" v-if="unreadnotification"></span>
					</div>
				</div>
					<div
						class="mt-3 bg-white main-menu"
						data-mdb-perfect-scrollbar="true"
						style="position: relative; overflow-y:auto; border-radius: 12px;"
					>
						<ul class="list-unstyled mb-0 menuitem" v-if="(this.rooms.length>0)" ref="menuitem">
							<li
								class="px-2"
								v-for="(room, key) in this.rooms"
								:id="room.roomId"
								:key="key"
								@click="loadRoomMessages(room.roomId)"
							>
								<a
									href="javascript:void(0)"
									class="d-block border-bottom py-2"
									:class="selectedRoomId == room.roomId?'active':''"
								>
									<div class="d-flex justify-content-between">
										<div class="pt-1">
											<p class="roomName mb-0 text-deepink">{{ room.roomName }}</p>
											<p
												class="small mb-0"
												:class="room.expiry_status == 'Active'?'text-success':'text-warning'"
											>{{ room.expiry_status }}</p>
										</div>
										<div class="pt-1" v-if="room.notification">
											<span
												class="badge bg-deepink rounded-pill text-white float-right"
											>{{room.notification_count}}</span>
										</div>
									</div>
								</a>
							</li>
						</ul>
						<ul v-else>
							<li class="px-2 border-bottom">No Requestors Found</li>
						</ul>
					</div>
				</div>

				<div class="col-md-8 col-lg-8 col-xl-9 bg-white pb-3 message-main-section">
					<div class="chatHead border-bottom">
						<div class="d-flex flex-row">
							<div class="pl-2 pt-2">
								<h4 class="mb-0 text-deepink">{{this.selectedRoomName}}</h4>
								<p class="small text-muted">{{ this.currentdbUserName }}</p>
							</div>
						</div>
					</div>

					<div class="message-section" v-if="(this.messages.length>0)">
						<div class="pt-3 pe-3 avoer chat-main-content" style="height:400px; overflow-y:auto;" v-chat-scroll :class="isShow ? 'chat-main-expand' : ''">
							<div
								class="d-flex flex-row"
								v-for="(message, key) in this.messages"
								:id="message.senderId"
								:class="message.class"
								:key="key"
							>
								<div style="max-width:85%" class="message-chat">
									<p
										class="py-2 px-3 message-text mb-0"
										:class="message.message_background_class"
									>{{message.content }}</p>
									<!-- <p class="small ms-3 mb-3 rounded-3 text-muted float-right">12:00 PM | Aug 13</p> -->

									<p class="small px-2 mb-3 rounded-3 text-muted d-flex" :class="message.class">
										{{
										message.username }}
									</p>
								</div>
							</div>
						</div>

						<div
							class="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2 position-relative write-message"
							v-if="this.selectedRoomStatus"
						>
							<input
								type="text"
								class="form-message w-100 rounded-pill"
								v-model="chatmessage"
								placeholder="Type message"
								@keyup.enter="trigger"
								v-on:keyup="showTyping"
							/>

							<a class="btn-message" href="javascript:void(0)" @click="saveMessage()" ref="sendmessage">
								<span class="material-icons-outlined">send</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import PerfectScrollbar from "perfect-scrollbar";
	import "perfect-scrollbar/css/perfect-scrollbar.css";

	function hasElement(className) {
		return document.getElementsByClassName(className).length > 0;
	}

	function initScrollbar(className) {
		if (hasElement(className)) {
			new PerfectScrollbar(`.${className}`);
		} else {
			setTimeout(() => {
				initScrollbar(className);
			}, 100);
		}
	}

	import { io } from "socket.io-client";

	export default {
		components: {
			// DashboardNavbar,
		},
		data() {
			return {
				socket: {},
				dashboar: true,
				rooms: [],
				messages: [],
				messagesLoaded: false,
				selectedRoomId: 0,
				selectedRoomStatus: 0,
				search_room: "",
				selectedRoomName: "",
				chatmessage: "",
				clickedRoomId: "",
				churchId: "",
				userId: "",
				typing: false,
				currentdbUserId: "",
				currentdbUserName: "",
				isShow:true,
				unreadnotification: false,
				unreadRoomIds:[],
			};
		},
		watch: {
			search_room: function () {
				this.getRooms();
			},
		},
		created() {
			// isShowMobile() {
				console.log(screen.width);
				if (screen.width <= 767) {
					this.isShow = false;
				} else {
					this.isShow = true;
				}
			// };
			if (this.$route.params.church && this.$route.params.user) {
				this.churchId = this.$route.params.church;
				this.userId = this.$route.params.user;
			}

			const timer = setInterval(() => {
				this.updateRooms();
			}, 10000);

			this.$once("hook:beforeDestroy", () => {
				clearInterval(timer);
			});
			console.log('created updated');
			this.socket = io("https://chat.prayer4.me:3000/");
			// this.socket = io("http://localhost:3000/");
			
		},
		beforeUnmount() {},
		methods: {
			getData: function(){
				let height=this.$refs.menuitem.clientHeight;
				console.log(height);
			},
			initScrollbar() {
				let isWindows = navigator.platform.startsWith("Win");
				if (isWindows) {
					initScrollbar("sidenav");
				}
			},
			showTyping() {
				// let message = this.currentdbUserName + " is typing";
			},
			trigger() {
				this.$refs.sendmessage.click();
			},
			saveMessage() {
				let message = this.chatmessage;
				if (message.length) {
					this.$http
						.get(`/user/savemessage`, {
							params: {
								message: this.chatmessage,
								selectedRoomId: this.selectedRoomId,
								identity: this.userId,
							},
						})
						.then((response) => {
							let message = response.data;
							if (message.last_inserted_id > 0) {
								this.messages.push({
									_id: message.last_inserted_id,
									content: this.chatmessage,
									senderId: message.sender_id,
									username: message.username,
									class: "justify-content-end",
									message_background_class:
										"bg-deepink text-white",
								});
								let messagedetails = {
									userid: this.currentdbUserId,
									username: this.currentdbUserName,
									message: this.chatmessage,
									last_inserted_id: message.last_inserted_id,
									roomId: this.selectedRoomId,
									roomName: this.selectedRoomName,
								};
								this.socket.emit(
									"newchatmessagesend",
									messagedetails
								);
								this.chatmessage = "";
							}
						})
						.catch((error) => {
							this.messagesLoaded = true;
							console.log(error);
						});
				}
			},
			loadRoomMessages: function (room_id) {
				if (this.unreadRoomIds.includes(room_id)) {          
					this.unreadRoomIds.splice(this.unreadRoomIds.indexOf(room_id), 1);              
				} 
				if (this.unreadRoomIds.length==0){
					this.unreadnotification=false;
				}
				this.selectedRoomId = room_id;
				this.fetchMessages();
			},
			fetchMessages() {
				this.messages = [];

				if (this.selectedRoomId > 0) {
					this.$http
						.get(`/user/getmessages`, {
							params: {
								room_id: this.selectedRoomId,
								identity: this.userId,
							},
						})
						.then((response) => {
							this.messagesLoaded = true;
							this.messages = response.data.messages;
							for (let i = 0; i < this.rooms.length; i++) {
								if (this.selectedRoomId == this.rooms[i].roomId) {
									this.rooms[i].notification = 0;
									this.rooms[i].notification_count = 0;
								}
							}

							this.selectedRoomName = response.data.room_name;
							this.selectedRoomStatus = response.data.expiry_status;
						})
						.catch((error) => {
							this.messagesLoaded = true;
							console.log(error);
						});
				}
			},
			getRooms() {
				this.$helper.showLoading();
				this.$http
					.get(`/user/groups`, {
						params: {
							search_room: this.search_room,
							churchId: this.churchId,
							userId: this.userId,
						},
					})
					.then((response) => {
						this.$helper.hideLoading();
						this.rooms = response.data.rooms;
						this.selectedRoomId =
							this.rooms.length > 0 ? this.rooms[0].roomId : 0;
						this.selectedRoomName =
							this.rooms.length > 0 ? this.rooms[0].roomName : "";
						// console.log(response.data.current_user_data, response.data.current_user_data.length, response.data.current_user_data.id);
						this.currentdbUserId = response.data.current_user_data.id;
						this.currentdbUserName =
							response.data.current_user_data.name;
						this.fetchMessages();
					})
					.catch((error) => {
						this.$helper.hideLoading();
						console.log(error);
					});
			},
			updateRooms() {
				let existingids = [];
				for (let val of this.rooms) {
					existingids.push(val.roomId);
				}

				this.$http
					.get(`/user/groups`, {
						params: {
							search_room: this.search_room,
							churchId: this.churchId,
							existingrooms: existingids,
						},
					})
					.then((response) => {
						let new_rooms = response.data.rooms;
						if (new_rooms.length > 0) {
							for (let i = 0; i < new_rooms.length; i++) {
								this.rooms.unshift({
									roomId: new_rooms[i].roomId,
									roomName: new_rooms[i].roomName,
								});
							}
						}
					})
					.catch((error) => {
						this.$helper.hideLoading();
						console.log(error);
					});
			},
		},
		mounted() {
			this.initScrollbar();
			this.$helper.hideLoading();
			this.getRooms();
			this.socket.on("newmessagereceived", (data) => {
				// console.log(data);
				if (this.currentdbUserId != data.userid) {
					// current user is different reciepient of the message only
					if (this.selectedRoomId == data.roomId) {
						this.messages.push({
							_id: data.last_inserted_id,
							content: data.message,
							senderId: (this.currentdbUserId == data.userid) ? this.currentdbUserId :  data.userid,
							username: (this.currentdbUserName == data.username) ? "" :  data.username,
							class: (this.currentdbUserId == data.userid) ? "justify-content-end" :"justify-content-start",
							message_background_class: (this.currentdbUserId == data.userid) ? "bg-deepink text-white" : "",
						});
					} else {
						// in another group

						for (let i = 0; i < this.rooms.length; i++) {
							if (data.roomId == this.rooms[i].roomId) {
								this.unreadRoomIds.push(this.rooms[i]);
								this.unreadnotification=true;
								this.rooms[i].notification = 1;
								this.rooms[i].notification_count =
									this.rooms[i].notification_count + 1;
							}
						}
						// console.log(this.rooms);
					}
				}
			});
		},
	};
</script>
<style>
	html, body{overflow-x: hidden;}
	#loading-bg {
	display: none !important;
	}
	#chat3{
	background: #fff;
	}
	.chatSideBar{
	background: #f0f0f2;
	}
	#chat3 .form-control {
	border-color: transparent;
	}

	#chat3 .form-control:focus {
	border-color: transparent;
	box-shadow: inset 0px 0px 0px 1px transparent;
	}
	.header-sec{
	display: flex;
	align-items: center;
	justify-content: space-between;
	}
	.w-full{width: 100%;}
	.badge-dot {
	border-radius: 50%;
	height: 10px;
	width: 10px;
	margin-left: 2.9rem;
	margin-top: -0.75rem;
	}

	.hamberger-menu{
	min-width: 20px;
	height: 14px;
	position: relative;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	display: none;
	}
	.hamberger-menu span{
	width: 100%;
	height: 2px;
	background: #000249;
	}
	.hamberger-menu:after, .hamberger-menu:before{
	content: "";
	display: block;
	position: absolute;
	top: 0;
	width: 100%;
	height: 2px;
	background: #000249;
	}
	.hamberger-menu:before{
	top: auto;
	bottom: 0;
	}
	.main-menu{
	-webkit-transition: height 0.8s linear;
	transition: height 0.8s linear;
	}
	.hamberger-menu.unreadnotification span,
	.hamberger-menu.unreadnotification:after,
	.hamberger-menu.unreadnotification:before{
	-webkit-box-shadow: 1px 1px 3px rgb(0 0 0 / 40%);
	box-shadow: 1px 1px 3px rgb(0 0 0 / 40%);
	}
	.hamberger-menu.unreadnotification span.bubble{
	position: absolute;
	top: -8px;
	left: auto;
	right: -8px;
	width: 12px;
	height: 12px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #ad2a56;
	box-shadow: 1px 1px 3px rgb(0 0 0 / 40%);
	z-index: 1;
	}
	.text-white, .message-text {
	color: #000 !important;
	}
	.text-warning {
	color: #c30000 !important;
	}
	.message-main-section{min-height: 555px; height: 100%;}
	@media (max-width: 767px) {
	.hamberger-menu{
	display: flex;
	}
	#chat3 {
	border-radius: 0px;
	}
	.chatSideBar{
	max-height: 75px;
	-webkit-transition: all 500ms ease-in-out 0s;
	transition: all 500ms ease-in-out 0s;
	}
	.chatSideBar.expand{
	max-height: 5000px;
	-webkit-transition: all 500ms ease-in-out 0s;
	transition: all 500ms ease-in-out 0s;
	}
	.chat-box{
	height: 100%;
	}
	.chat-body{padding: 0rem !important;}
	.chat-main-content{height: 70vh !important; padding-bottom: 85px;}
	.chat-main-content.chat-main-expand{height: 57vh !important;}
	.write-message{position: fixed !important; left: 0rem; right: 0rem; bottom: 0rem; padding: 0 1rem 1rem 1rem; background-color: #fff; border-bottom-right-radius: 12px; border-bottom-left-radius: 12px;}
	.write-message .btn-message{right: 30px;}
	.message-chat{max-width: 100% !important;}
	.chat-main-content .mr-8{margin-right: 1.5rem !important;}
	.chat-main-content .ml-8{margin-left: 1.5rem !important;}
	}

</style>